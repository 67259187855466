import { Link } from "react-router-dom";
import "./BenefitsSection.css";
import ROI from "../../../assets/img/benefits/roi.jpeg";
import MultipleRevenueStreams from "../../../assets/img/benefits/Multiple-Revenue-Streams.jpeg";
import BulkPurchasing from "../../../assets/img/benefits/Bulk-Purchasing.jpeg";
import ComprehensiveTraining from "../../../assets/img/benefits/Comprehensive-Training.jpeg";
export default function BenefitsSection() {
  return (
    <section className="benefits-section section-spacing">
      <div className="container-fluid">
        <div className="row justify-content-between mb-4">
          <div className="col-lg-5">
            <div className="main-heading">
              <span>Benefits of being a Franchise Partner</span>
              <h2>
                Joining the SuperKirana network comes with a host of benefits
                designed to ensure the success of your store.
              </h2>
            </div>
          </div>
          <div className="col-lg-5">
            <p className="text-white text-justify">
              Our support doesn’t stop after the launch. SuperKirana offers
              ongoing operational support, including regular store visits,
              performance reviews, and troubleshooting assistance. We’re here to
              help you overcome any challenges and optimize your store’s
              performance and generate more profits.
            </p>
            <Link to="/franchise-benefits" className="theme-btn mt-3">
              Read More
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className="benifit-card">
              <div className="position-relative">
                <img
                  src={ROI}
                  className="img-fluid"
                  alt="High Return on Investment (ROI)"
                />
                <h5>High Return on Investment</h5>
              </div>
              <div className="content">
                <p>
                  SuperKirana’s business model is designed to deliver a high
                  ROI. With efficient operations, modern store designs, and
                  effective marketing, franchisees can expect significant
                  returns on their investment.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className="benifit-card">
              <div className="position-relative">
                <img
                  src={MultipleRevenueStreams}
                  className="img-fluid"
                  alt="Multiple Revenue Streams"
                />
                <h5>Multiple Revenue Streams</h5>
              </div>
              <div className="content">
                <p>
                  SuperKirana stores generate revenue from various sources,
                  including product sales, promotions, and loyalty programs.
                  This diversification reduces risk and enhances overall
                  profitability.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className="benifit-card">
              <div className="position-relative">
                <img
                  src={BulkPurchasing}
                  className="img-fluid"
                  alt="Bulk Purchasing Power"
                />
                <h5>Bulk Purchasing Power</h5>
              </div>
              <div className="content">
                <p>
                  Being part of the SuperKirana franchise network, you’ll have
                  access to various bulk purchasing discounts, reducing your
                  cost of goods and increasing your margins on branded high
                  quality products.
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3 mb-4 mb-lg-0">
            <div className="benifit-card">
              <div className="position-relative">
                <img
                  src={ComprehensiveTraining}
                  className="img-fluid"
                  alt="Comprehensive Training"
                />
                <h5>Comprehensive Training</h5>
              </div>
              <div className="content">
                <p>
                  SuperKirana provides thorough training programs for you and
                  your staff, covering everything from customer service to
                  inventory management. Our training ensures that your store
                  operates smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
