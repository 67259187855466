import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MainNavbar from "../../MainNavbar/MainNavbar";
import "./Header.css";
import { FaHandshake } from "react-icons/fa";
import { Button, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import { LiaNetworkWiredSolid } from "react-icons/lia";
import { IoStorefrontOutline } from "react-icons/io5";
import { TbReceiptRupee, TbTruckDelivery } from "react-icons/tb";
import { MdOutlineStars, MdOutlineWarehouse } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import Notiflix from "notiflix";
import PostApiCall from "../../../APi/PostApi";
import {IoSync } from "react-icons/io5";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Pagination } from "swiper/modules";

export default function Header() {
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Loader, setLoader] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    name: "",
    email: "",
    mobile: "",
    city: "",
    message: "",
    captcha: "",
    EmailRegex:
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    NumRegex: /^[0-9]*$/,
  });
  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("iorotate");
    }
  }, [Rotatecaptcha]);
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000)
      .toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }
  const [captcha_number, setcaptcha_number] = useState("");
  useEffect(() => {
    setcaptcha_number(
      Math.floor(100000 + Math.random() * 90000000)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    );
  }, []);
  // notiflix message position
  useEffect(() => {
    Notiflix.Notify.init({
      width: "280px",
      position: "center-bottom",
      distance: "10px",
      opacity: 1,
      clickToClose: true,
      showOnlyTheLastOne: true,
      fontSize: "15px",
      timeout: 4000,
    });
  }, []);


  return (
    <section className="header-section section-spacing-x">
      <MainNavbar />
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6 mb-lg-5 my-4">
            <Swiper
              spaceBetween={30}
              // effect={"fade"}
              // pagination={false}
              // pagination={{
              //   clickable: true,
              // }}
              // autoplay={{
              //   delay: 2000,
              //   disableOnInteraction: false,
              // }}
              draggable={false}
              modules={[EffectFade, Autoplay, Pagination]}
              className="mySwiper pb-5">
              <SwiperSlide>
                <div className="main-heading">
                  <span>Join the Superkirana Revolution</span>
                  <h1>
                    Transform Your Store & Boost Your Profits! Empowering &
                    Turning your Kirana Stores into a Super Retail Hub.
                  </h1>
                  <div>
                    <Link to="/contact-us" className="theme-btn">
                      Apply for a Franchise
                    </Link>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                {/* <div className="main-heading">
                  <span>Join the Superkirana Revolution</span>
                  <h1>
                    Transform Your Store & Boost Your Profits! Empowering &
                    Turning your Kirana Stores into a Super Retail Hub.
                  </h1>
                  <div>
                    <Link to="" className="theme-btn">
                      Apply for a Franchise
                    </Link>
                  </div>
                </div> */}
              </SwiperSlide>
              <SwiperSlide>
                {/* <div className="main-heading">
                  <span>Join the Superkirana Revolution</span>
                  <h1>
                    Transform Your Store & Boost Your Profits! Empowering &
                    Turning your Kirana Stores into a Super Retail Hub.
                  </h1>
                  <div>
                    <Link to="" className="theme-btn">
                      Apply for a Franchise
                    </Link>
                  </div>
                </div> */}
              </SwiperSlide>
              <SwiperSlide>
                {/* <div className="main-heading">
                  <span>Join the Superkirana Revolution</span>
                  <h1>
                    Transform Your Store & Boost Your Profits! Empowering &
                    Turning your Kirana Stores into a Super Retail Hub.
                  </h1>
                  <div>
                    <Link to="" className="theme-btn">
                      Apply for a Franchise
                    </Link>
                  </div>
                </div> */}
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="row">
          <div className="col-12 px-0">
            <div className="six-cards-box">
              <div className="six-card">
                <div className="icon">
                  <LiaNetworkWiredSolid />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Largest Network of Stores</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
              <div className="six-card">
                <div className="icon">
                  <IoStorefrontOutline />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Modern Spacious Interior</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
              <div className="six-card">
                <div className="icon">
                  <TbReceiptRupee />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Budget Friendly Models</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
              <div className="six-card">
                <div className="icon">
                  <TbTruckDelivery />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Logistics Management</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
              <div className="six-card">
                <div className="icon">
                  <MdOutlineWarehouse />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Supply Chain & Warehousing</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
              <div className="six-card">
                <div className="icon">
                  <MdOutlineStars />
                </div>
                <div className="content">
                  <div className="heading">
                    <h4>Wide Range of Brands</h4>
                  </div>
                  <div className="button-box">
                    <Link to="">Know More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 bg-white">
            <div className="overview-card">
              <div className="left-section">
                <div className="main-heading">
                  <span className="fs-3 fw-bold">
                    Aapka Vyapar, Hamari Jimmedaari
                  </span>
                  <h2 className="fs-4">
                    Step Into the Future with Superkirana
                  </h2>
                </div>
                <p>
                  <b>Superkirana</b> is modernizing the traditional Kirana
                  stores by providing access to better infrastructure,
                  technology, branding, and ongoing business development.
                </p>
                <p>
                  We help you compete with large retailers while maintaining
                  your local customer base.
                </p>
              </div>
              <div className="right-section">
                <p>
                  Superkirana is a franchise network aimed at transforming and
                  empowering local Kirana stores with technology and improved
                  business models to ensure higher profitability and smoother
                  operations.
                </p>
                <p>
                  To elevate kirana stores Superkirana has crafted a range of
                  franchising options that can cater to your specific budgets.
                  This gives you an opportunity to reap more profits with lesser
                  investments.
                </p>
                <div className="d-flex justify-content-end">
                  <Link to="/the-brand" className="theme-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex align-items-center justify-content-center form-bg">
            <div className="enquiry-form row">
              <h4 className="text-white mb-3">Apply for a Franchise</h4>
              <div className="col-lg-6 mb-3">
                <Input placeholder="Full Name*"
                  className="text-white"
                  name="name"
                  value={Enquiry.name}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 mb-3">
                <Input placeholder="Email*"
                  className="text-white"
                  name="email"
                  value={Enquiry.email}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-lg-6 mb-3">
                <Input placeholder="Mobile No.*"
                  className="text-white"
                  name="mobile"
                  value={Enquiry.mobile}
                  onChange={(e) => {
                    if (e.target.value.length <= 10)
                      setEnquiry({
                        ...Enquiry,
                        [e.target.name]: e.target.value.replace(/\D/g, ""),
                      });
                  }}
                />
              </div>
              <div className="col-lg-6 mb-3">
                <Input placeholder="State*"
                  className="text-white"
                  name="city"
                  value={Enquiry.city}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12 mb-3">
                <TextArea rows={4}
                  className="text-white"
                  name="message"
                  placeholder="Message"
                  value={Enquiry.message}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-6 mb-3">
                <p className="me-2 mb-2 d-flex justify-content-between captcha-home-value-size text-white px-2">
                  {captcha_number}
                  <span
                    onClick={() => {
                      setRotatecaptcha("iorotate");
                      Genratecaptcha();
                    }}
                    className={Rotatecaptcha == "iorotate" ? "iorotate" : ""}
                  >
                    <IoSync className="aButton" />
                  </span>
                </p>
              </div>
              <div className="col-6 mb-3">
                <Input placeholder="Enter Captcha*"
                  className="text-white"
                  name="captcha"
                  value={Enquiry.captcha}
                  onChange={(e) =>
                    setEnquiry({
                      ...Enquiry,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <Button className="theme-btn"
                  onClick={() => {
                    if (Enquiry.name != "") {
                      if (Enquiry.email != "") {
                        if (Enquiry.EmailRegex.test(Enquiry.email)) {
                          if (Enquiry.mobile != "") {
                            if (Enquiry.mobile.length == 10) {
                              if (Enquiry.city != "") {
                                // if (Enquiry.message != "") {
                                  if (
                                    Enquiry.captcha.toString() ==
                                    captcha_number.toString()
                                  ) {
                                    Notiflix.Loading.arrows("Please wait...");
                                    PostApiCall.postRequest(
                                      {
                                        name: Enquiry.name,
                                        mobile: Enquiry.mobile,
                                        email: Enquiry.email,
                                        message: Enquiry.message,
                                        city: Enquiry.city,
                                        clientid: 2072,
                                      },
                                      "SuperkiranaEnquiry"
                                    ).then((results2) =>
                                      results2.json().then((obj2) => {
                                        if (
                                          results2.status == 200 ||
                                          results2.status == 201
                                        ) {
                                          setLoader(false);
                                          Notiflix.Notify.success(
                                            "Thank you, our team will contact you shortly!"
                                          );
                                          setTimeout(() => {
                                            window.location.reload();
                                          }, 2000);
                                        } else Notiflix.Loading.remove();
                                      })
                                    );
                                  } else {
                                    Notiflix.Notify.failure(
                                      "Please enter valid captcha"
                                    );
                                  }
                                }
                              //    else {
                              //     Notiflix.Notify.failure(
                              //       "Please enter message"
                              //     );
                              //   }
                              // }
                              else {
                                Notiflix.Notify.failure(
                                  "Please enter state"
                                );
                              }
                            }
                            else {
                              Notiflix.Notify.failure(
                                "Please enter valid mobile number"
                              );
                            }
                          } else {
                            Notiflix.Notify.failure("Please enter mobile number");
                          }
                        }
                        else {
                          Notiflix.Notify.failure(
                            "Please enter valid email"
                          );
                        }
                      } else {
                        Notiflix.Notify.failure("Please enter email");
                      }
                    }
                    else {
                      Notiflix.Notify.failure("Please enter name");
                    }
                  }}
                >
                  {Loader == true ? (
                    <div class="d-flex justify-content-center">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ width: "1.4rem", height: "1.4rem" }}
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <span>Submit </span>
                  )}
                </Button>
              </div>
              {/* <iframe
                src="https://globaltrendz.info/superkiranahenquiry/2072/enquiry"
                frameborder="0"></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
