import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomePage from "./Pages/HomePage";
import MainNavbar from "./Components/MainNavbar/MainNavbar";
import MainFooter from "./Components/MainFooter/MainFooter";
import BottomFixedLinks from "./Components/BottomFixedLinks/BottomFixedLinks";
import TheBrand from "./Pages/TheBrand";
import Brands from "./Pages/Brands";
import WhyUs from "./Pages/WhyUs";
import HowWeWork from "./Pages/HowWeWork";
// import OurStores from "./Pages/OurStores";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import Blogs from "./Pages/Blogs";
import BlogDetail from "./Pages/BlogDetail";
import ContactUs from "./Pages/ContactUs";
import GetStarted from "./Pages/GetStarted";
import Careers from "./Pages/Careers";
import Partnerships from "./Pages/Partnerships";
import MarketOverview from "./Pages/Franchiise/MarketOverview";
import FranchiseModels from "./Pages/Franchiise/FranchiseModels";
import InvestmentFinancials from "./Pages/Franchiise/InvestmentFinancials";
import FranchiseBenefits from "./Pages/Franchiise/FranchiseBenefits";
import SiteSelection from "./Pages/Franchiise/SiteSelection";
import SuccessStories from "./Pages/Franchiise/SuccessStories";
import FranchiseSupportTraining from "./Pages/Services/FranchiseSupportTraining";
import SupplyChainManagement from "./Pages/Services/SupplyChainManagement";
import MarketingPromotions from "./Pages/Services/MarketingPromotions";
import NewsMedia from "./Pages/NewsMedia";
import Faq from "./Pages/Faq";
import Gallery from "./Pages/Gallery";
import RevolutionizingBlog from "./Pages/RevolutionizingBlog";
import RevolutionaryBlog from "./Pages/RevolutionaryBlog";
import HowSuperKiranaBlog from "./Pages/HowSuperKiranaBlog";
import InventoryManagement from "./Pages/Services/InventoryManagement";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
  return (
    <>
      <BrowserRouter>
        {/* {window.location.pathname === "/" ? <></> : <MainNavbar />} */}
        <MainNavbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/the-brand" element={<TheBrand />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/why-us" element={<WhyUs />} />
          <Route path="/how-we-work" element={<HowWeWork />} />
          {/* <Route path="/our-stores" element={<OurStores />} /> */}
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog-detail" element={<BlogDetail />} />
          <Route
            path="/revolutionizing-local-kirana-stores-across-india"
            element={<RevolutionizingBlog />}
          />
          <Route
            path="/a-revolutionary-initiative-by-mathills-pvt-ltd"
            element={<RevolutionaryBlog />}
          />
          <Route
            path="/how-superKirana-can-help-you-grow-your-kirana-business"
            element={<HowSuperKiranaBlog />}
          />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/partnerships" element={<Partnerships />} />
          <Route path="/market-overview" element={<MarketOverview />} />
          <Route path="/franchise-models" element={<FranchiseModels />} />
          <Route
            path="/investment-and-financials"
            element={<InvestmentFinancials />}
          />
          <Route path="/franchise-benefits" element={<FranchiseBenefits />} />
          <Route path="/site-selection" element={<SiteSelection />} />
          <Route path="/success-stories" element={<SuccessStories />} />
          <Route
            path="/franchise-support-and-training"
            element={<FranchiseSupportTraining />}
          />
          <Route
            path="/supply-chain-management"
            element={<SupplyChainManagement />}
          />
          <Route
            path="/inventory-management"
            element={<InventoryManagement />}
          />
          <Route
            path="/marketing-and-promotions"
            element={<MarketingPromotions />}
          />
          <Route path="/news-and-media" element={<NewsMedia />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/gallery" element={<Gallery />} />
        </Routes>
        <MainFooter />
        <BottomFixedLinks />
      </BrowserRouter>
    </>
  );
}

export default App;
