import { Collapse } from "antd";
import InnerBannerImg from "../assets/img/homa-banner/banner.jpeg";
import InnerBanner from "../Components/InnerBanner/InnerBanner";
export default function Faq() {
  const items = [
    {
      key: "1",
      label: "1. What is Superkirana?",
      children: (
        <p>
          Superkirana is a retail chain that offers a unique franchise
          opportunity to open and operate your own Kirana store. We provide the
          support, resources, and brand recognition to help you succeed in the
          grocery retail business.
        </p>
      ),
    },
    {
      key: "2",
      label: "2. How can I apply for a Superkirana franchise?",
      children: (
        <p>
          You can apply for a franchise by filling out the form on our
          [Franchise Opportunity page]. Once submitted, our team will review
          your application and contact you for further steps.
        </p>
      ),
    },
    {
      key: "3",
      label:
        "3. What are the requirements to open a Superkirana franchise store?",
      children: (
        <p>
          To open a Superkirana franchise, you need to have a suitable retail
          space in a good location, a minimum investment amount, and a
          commitment to our brand values and operational standards. Detailed
          requirements will be discussed during the application process.
        </p>
      ),
    },
    {
      key: "4",
      label: "4. What kind of support does Superkirana provide to franchisees?",
      children: (
        <p>
          Superkirana offers comprehensive support, including store setup
          guidance, staff training, inventory management, marketing assistance,
          and ongoing operational support to ensure your store runs smoothly.
        </p>
      ),
    },
    {
      key: "5",
      label: "5. How much does it cost to start a Superkirana franchise?",
      children: (
        <p>
          The cost varies depending on factors such as location, store size, and
          inventory needs. We will provide a detailed breakdown of the
          investment required during the franchise application process.
        </p>
      ),
    },
    {
      key: "6",
      label:
        "6. Do I need prior experience in retail to open a Superkirana franchise?",
      children: (
        <p>
          While prior experience in retail can be beneficial, it is not
          mandatory. Superkirana provides extensive training and support to help
          you manage your store effectively, regardless of your background.
        </p>
      ),
    },
    {
      key: "7",
      label: "7. How long does it take to open a Superkirana store?",
      children: (
        <p>
          The timeline can vary based on factors like location selection, store
          setup, and obtaining necessary approvals. On average, it takes a few
          months from signing the franchise agreement to opening the store.
        </p>
      ),
    },
    {
      key: "8",
      label: "8. What products will I sell in my Superkirana store?",
      children: (
        <p>
          Your Superkirana store will offer a wide range of products, including
          groceries, household items, personal care products, and more. We
          provide a curated product list to ensure high-quality offerings.
        </p>
      ),
    },
    {
      key: "9",
      label: "9. Can I run multiple Superkirana stores?",
      children: (
        <p>
          Yes, if you successfully manage your first store, you may have the
          opportunity to expand and operate additional Superkirana stores.
        </p>
      ),
    },
    {
      key: "10",
      label: "10. Who should I contact if I have more questions?",
      children: (
        <p>
          For any additional questions, feel free to call our support team at{" "}
          <a href="tel:+919205781326">+91 92057 81326</a>,{" "}
          <a href="tel:+919205781322">+91 92057 81322</a> or by emailing us at
          <a href="mailto:info@mathillsgroup.com">info@mathillsgroup.com</a>. We’re
          here to help!
        </p>
      ),
    },
  ];
  return (
    <>
      <InnerBanner bgImage={InnerBannerImg} />
      <section className="section-spacing faq-page">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="main-heading main-heading-center">
                <span>What You Need to Know</span>
                <h2>Answers to Your Most Common Questions</h2>
              </div>
              <Collapse
                accordion
                items={items}
                bordered={false}
                defaultActiveKey={["1"]}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
