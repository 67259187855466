import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import "./MainNavbar.css";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube } from "react-icons/fa";
import Logo from "../../assets/img/logo/superkirana-logo.png";
import { Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { IoIosArrowDown, IoIosCall } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import { IoMail, IoReorderThreeOutline } from "react-icons/io5";
import { RxHamburgerMenu } from "react-icons/rx";
import { GiHamburgerMenu } from "react-icons/gi";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
export default function MainNavbar() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const items1 = [
    {
      label: <a className="text-white" href="/market-overview">Market Overview</a>,
      key: "1",
    },
    {
      label: <a className="text-white" href="/franchise-models">Franchise Models</a>,
      key: "2",
    },
    {
      label: (
        <a className="text-white" href="/investment-and-financials">
          Investment & Financials
        </a>
      ),
      key: "3",
    },
    {
      label: <a className="text-white" href="/franchise-benefits">Franchise Benefits</a>,
      key: "4",
    },
    {
      label: <a className="text-white" href="/site-selection">Site Selection</a>,
      key: "5",
    },
    {
      label: <a className="text-white" href="/success-stories">Success Stories</a>,
      key: "6",
    },
  ];

  const items2 = [
    {
      label: (
        <a className="text-white" href="/franchise-support-and-training">
          Franchise Support & Training
        </a>
      ),
      key: "1",
    },
    {
      label: (
        <a className="text-white" href="/supply-chain-management">
          Supply Chain Management
        </a>
      ),
      key: "2",
    },
    {
      label: (
        <a className="text-white" href="/inventory-management">Inventory Management</a>
      ),
      key: "3",
    },
    {
      label: (
        <a className="text-white" href="/marketing-and-promotions">Marketing & Promotions</a>
      ),
      key: "4",
    },
  ];
  return (
    <div className="main-navbar">
      <div className="container-fluid d-none d-lg-block">
        <div className="row">
          <div className="col-12">
            <div className="top-bar">
              <ul>
                <li>
                  <a href="mailto:info@mathillsgroup.com">
                    <IoMail />
                    info@mathillsgroup.com
                  </a>
                </li>
                <li>
                  <a href="tel:+919205781326">
                    <IoIosCall /> +91 92057 81326
                  </a>
                </li>
                <li className="p-0">
                  <ul className="sm-links">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61563814711964" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/superkirana_official/?hl=en" target="_blank" rel="noopener noreferrer">
                        <FaSquareInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/104161632/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@Superkirana" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </li>
                {/* <li className="p-0">
                  <a href="" className="theme-btn">
                    Download Brochure
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Navbar expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img src={Logo} alt="superkirana logo" className="img-fluid" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`}>
            <GiHamburgerMenu />
          </Navbar.Toggle>
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end">
            <Offcanvas.Header closeButton>
              <Nav.Link href="/">
                <img src={Logo} alt="superkirana logo" className="img-fluid" />
              </Nav.Link>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-end flex-grow-1">
                <Nav.Link href="/the-brand">The Brand</Nav.Link>
                <Nav.Link href="/why-us">Why us</Nav.Link>
                <Nav.Link href="/how-we-work">How we work?</Nav.Link>
                <Dropdown
                  placement="bottomRight"
                  className="nav-link cursor-pointer d-none d-lg-block"
                  menu={{
                    items: items1,
                  }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Franchise
                      <IoIosArrowDown />
                    </Space>
                  </a>
                </Dropdown>
                <NavDropdown
                  title={
                    <>
                      Franchise
                      <IoIosArrowDown />
                    </>
                  }
                  className="d-block d-lg-none"
                  id={`offcanvasNavbarDropdown-expand-lg`}>
                  <NavDropdown.Item href="/market-overview">
                    Market Overview
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/franchise-models">
                    Franchise Models
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/investment-and-financials">
                    Investment & Financials
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/franchise-benefits">
                    Franchise Benefits
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/site-selection">
                    Site Selection
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/success-stories">
                    Success Stories
                  </NavDropdown.Item>
                </NavDropdown>
                <Dropdown
                  placement="bottomRight"
                  className="nav-link cursor-pointer d-none d-lg-block"
                  menu={{
                    items: items2,
                  }}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Services
                      <IoIosArrowDown />
                    </Space>
                  </a>
                </Dropdown>
                <NavDropdown
                  title={
                    <>
                      Services
                      <IoIosArrowDown />
                    </>
                  }
                  className="d-block d-lg-none"
                  id={`offcanvasNavbarDropdown-expand-lg`}>
                  <NavDropdown.Item href="/franchise-support-and-training">
                    Franchise Support & Training
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/supply-chain-management">
                    Supply Chain Management
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/inventory-management">
                    Inventory Management
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/marketing-and-promotions">
                    Marketing & Promotions
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link href="/get-started">Get Started</Nav.Link>
                <Nav.Link href="/partnerships">Partnerships</Nav.Link>
                {/* <Nav.Link href="/our-stores">Our Stores</Nav.Link> */}
                <Nav.Link href="/careers">Careers</Nav.Link>
                <Nav.Link href="/contact-us">Contact us</Nav.Link>
              </Nav>
              <div className="d-block d-lg-none">
                <div className="mobile-link-box">
                  <h4>Follow Us</h4>
                  <ul className="mobile-sm-links">
                    <li>
                      <a href="https://www.facebook.com/profile.php?id=61563814711964" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/superkirana_official/?hl=en" target="_blank" rel="noopener noreferrer">
                        <FaSquareInstagram />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.linkedin.com/company/104161632/admin/dashboard/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                      </a>
                    </li>
                    <li>
                      <a href="https://www.youtube.com/@Superkirana" target="_blank" rel="noopener noreferrer">
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                  <h4>Contact Us</h4>
                  <ul className="mobile-contact-links">
                    <li>
                      <a href="mailto:info@mathillsgroup.com">
                        <div className="icon">
                          <IoMail />
                        </div>
                        info@mathillsgroup.com
                      </a>
                    </li>
                    <li>
                      <a href="tel:+919205781326">
                        <div className="icon">
                          <IoIosCall />
                        </div>{" "}
                        +91 92057 81326
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}
